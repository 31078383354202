import axios from 'axios';

const apiKey = '2404d4f';
const baseUrl = 'https://www.omdbapi.com';


const baseAxios = axios.create({
    baseURL: baseUrl,
    params: {
        'apiKey': apiKey,
    }
})


const searchByTitle = title => {
    return baseAxios.get('/', {
        params: {
            s: title,
        }
    });
}

const retrieveByImdbId = imdbId => {
    return baseAxios.get('/', {
        params: {
            i: imdbId,
        }
    });
}


const omdbApi = {
    searchByTitle,
    retrieveByImdbId,
}

export default omdbApi;