import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import api from "../network/api";
import {Skeleton, Button, Tooltip, Typography, Tag} from 'antd';
import {HeartOutlined, HeartFilled, DeleteOutlined} from '@ant-design/icons';
import omdbApi from "../network/omdbApi";
import breakpoints from "../styles/breakpoints";
import {useParams, useLocation, Link} from 'react-router-dom';
import {EyeFilled} from '@ant-design/icons';
import mixpanel from "mixpanel-browser";
import {RiBookmarkFill} from "react-icons/all";
import AuthContext from "../authContext";
import routes from "../routes";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

const Content = styled.div``;

const Header = styled.div`
  ${Content} {
    margin-top: 24px;
    max-width: 600px;
  }

  @media (min-width: ${breakpoints.lg}) {
    display: flex;

    ${Content} {
      flex: 1;
      margin-left: 48px;
    }
  }
`;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const ShowDetailPage = () => {
    const [savedShow, setSavedShow] = useState();
    const [showDetails, setShowDetails] = useState();
    const [friendsRecommended, setFriendsRecommended] = useState();
    const [friendsWatchlisted, setFriendsWatchlisted] = useState();
    const [loading, setLoading] = useState(false);
    const {user} = useContext(AuthContext);

    const {imdbId} = useParams();

    const query = useQuery();
    const referrerUuid = query.get("referrer");

    useEffect(() => {
        mixpanel.track("Viewed Show Detail Page", {
            imdb_id: imdbId,
            referrer_uuid: referrerUuid,
        });
        setSavedShow(null);
        setShowDetails(null);
        fetchSavedShow();
        fetchShowDetails();
        fetchFriendsRecommended();
        fetchFriendsWatchlisted();
    }, [imdbId]);

    const fetchSavedShow = async () => {
        setLoading(true);
        const response = await api.fetchSavedShows({
            user__uuid: user.uuid,
            show__imdb_id: imdbId,
        });
        setSavedShow(response.data.results[0]);
        setLoading(false);
    }

    const fetchShowDetails = async () => {
        const response = await omdbApi.retrieveByImdbId(imdbId);
        setShowDetails(response.data);
    }

    const fetchFriendsRecommended = async () => {
        const response = await api.fetchSavedShows({
            show__imdb_id: imdbId,
            recommended: true,
        });
        setFriendsRecommended(response.data.results.map(savedShow => savedShow.user));
    };

    const fetchFriendsWatchlisted = async () => {
        const response = await api.fetchSavedShows({
            show__imdb_id: imdbId,
            watched: false,
        });
        setFriendsWatchlisted(response.data.results.map(savedShow => savedShow.user));
    };

    const handleRecommendClicked = async () => {
        if (!savedShow) {
            const response = await api.saveShow({
                imdb_id: imdbId,
                watched: true,
                recommended: true,
                referrer: referrerUuid,
            });
            setSavedShow(response.data);
            mixpanel.track("Show Detail - Clicked Recommend", {
                imdb_id: imdbId,
                referrer: referrerUuid,
            });
            return;
        }

        if (!savedShow.recommended) {
            // Recommend
            const response = await api.updateSavedShow(savedShow.uuid, {
                recommended: true,
                watched: true,
            });
            setSavedShow(response.data);
            mixpanel.track("Show Detail - Clicked Recommend", {
                imdb_id: imdbId,
                referrer: referrerUuid,
            });
        } else {
            // Unrecommend
            const response = await api.updateSavedShow(savedShow.uuid, {
                recommended: false,
                watched: true,
            });
            setSavedShow(response.data);
            mixpanel.track("Show Detail - Clicked Unrecommend", {
                imdb_id: imdbId,
                referrer: referrerUuid,
            });
        }
    }

    const handleMarkWatchedClicked = async () => {
        const response = await api.updateSavedShow(savedShow.uuid, {
            watched: !savedShow.watched,
        });
        setSavedShow(response.data);
        mixpanel.track(`Show Detail - Clicked ${!savedShow.watched ? 'Watched' : 'Not Watched'}`, {
            imdb_id: imdbId,
            referrer: referrerUuid,
        });
    }

    const handleRemoveClicked = async () => {
        const response = await api.removeSavedShow(savedShow.uuid);
        setSavedShow(null);
        mixpanel.track("Show Detail - Clicked Remove", {
            imdb_id: imdbId,
            referrer: referrerUuid,
        });
    }

    const handleSaveClicked = async () => {
        const response = await api.saveShow({
            imdb_id: imdbId,
            referrer: referrerUuid,
        });
        setSavedShow(response.data);
        mixpanel.track("Show Detail - Clicked Save", {
            imdb_id: imdbId,
            referrer: referrerUuid,
        });
    }

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            <Header>
                {showDetails && (
                    <img src={showDetails.Poster} alt={showDetails.Title} style={{maxWidth: '50%'}}/>
                )}
                <Content>
                    {showDetails && (
                        <>
                            <h1>
                                {showDetails.Title}
                            </h1>
                        </>
                    )}
                    <div
                        style={{
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridGap: 16,
                            justifyContent: 'flex-start'
                        }}
                    >
                        {loading ? (
                            <>
                                <Skeleton.Button active/>
                                <Skeleton.Button active shape={'circle'}/>
                            </>
                        ) : (
                            <>
                                {savedShow ? (
                                    <Button onClick={handleMarkWatchedClicked}>
                                        {savedShow.watched ? 'Mark as not watched' : 'Mark as watched'}
                                    </Button>
                                ) : (
                                    <Button onClick={handleSaveClicked}>Save to my list</Button>
                                )}
                                <Tooltip title={savedShow?.recommended ? "Unrecommend" : "Recommend"}>
                                    <Button
                                        shape={'circle'}
                                        icon={savedShow?.recommended ? <HeartFilled/> : <HeartOutlined/>}
                                        style={{
                                            color: savedShow?.recommended ? 'red' : 'white'
                                        }}
                                        onClick={handleRecommendClicked}
                                    />
                                </Tooltip>
                                {savedShow && (
                                    <Tooltip title={"Remove from my list"}>
                                        <Button
                                            shape={'circle'}
                                            icon={<DeleteOutlined/>}
                                            onClick={handleRemoveClicked}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                    {savedShow && (
                        <div style={{marginTop: 16}}>
                            {savedShow.watched ? (
                                <Tag icon={<EyeFilled/>}>Watched</Tag>
                            ) : (
                                <Tag icon={<RiBookmarkFill style={{marginBottom: -1, marginRight: 2}}/>}>Saved to
                                    watchlist</Tag>
                            )}
                        </div>
                    )}
                    <div>
                        {friendsRecommended?.length > 0 && (
                            <div style={{marginTop: 32}}>
                                <span>Recommended: </span>
                                <div>
                                    {friendsRecommended.map(user => (
                                        <span key={user.uuid}>
                                            <Link to={routes.friend.replace(':uuid', user.uuid)}>
                                                {user.username}
                                            </Link>
                                        </span>
                                    )).reduce((prev, curr) => [prev, ' | ', curr])}
                                </div>
                            </div>
                        )}
                        {friendsWatchlisted?.length > 0 && (
                            <div style={{marginTop: 16}}>
                                <span>Watchlisted: </span>
                                <div>
                                    {friendsWatchlisted.map(user => (
                                        <span key={user.uuid}>
                                            <Link to={routes.friend.replace(':uuid', user.uuid)}>
                                                {user.username}
                                            </Link>
                                        </span>
                                    )).reduce((prev, curr) => [prev, ' | ', curr])}
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{marginTop: 32}}>
                        {showDetails ? (
                            <>
                                <div>
                                    <Typography.Text>
                                        IMDB: {showDetails.imdbRating}
                                    </Typography.Text>
                                </div>
                                <div style={{marginTop: 16}}>
                                    <Typography.Text>
                                        {showDetails.Plot}
                                    </Typography.Text>
                                </div>
                            </>
                        ) : (
                            <>
                                <Skeleton paragraph={{width: '100%'}} active/>
                            </>
                        )}
                    </div>
                </Content>
            </Header>
        </div>
    )
}

export default ShowDetailPage;