import React from 'react';
import routes from "../routes";
import {Link} from "react-router-dom";


const ShowThumbnail = ({savedShow, show, style, referrerUuid, ...props}) => {
    const showObj = savedShow?.show || show;

    let linkUrl = routes.show.replace(':imdbId', showObj.imdb_id);

    if (referrerUuid) {
        linkUrl = `${linkUrl}?referrer=${referrerUuid}`;
    }

    return (
        <div
            style={{
                cursor: 'pointer',
                background: showObj.poster_url ? 'none' : 'black',
                ...style,
            }} {...props}
        >
            <Link to={linkUrl}>
                <img style={{width: '100%', height: 'auto'}} src={showObj.poster_url}/>
                {!showObj.poster_url && (
                    <div style={{padding: 16, textAlign: 'center'}}>
                        <div>
                            {showObj.title}
                        </div>
                        <div>{showObj.year_label}</div>
                    </div>
                )}
            </Link>
        </div>
    )
}


export default ShowThumbnail;