import React, {useCallback, useState} from 'react';
import {Input} from "antd";
import omdbApi from "../network/omdbApi";
import lodash from 'lodash';
import ShowSnippet from "../components/ShowSnippet";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import routes from "../routes";

const Overlay = styled.div`
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.7;
`;

const SearchResultContainer = styled.div`
  color: white;
  background: black;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  max-height: 80vh;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
`;

const StyledInput = styled(Input)`
  background: black;
  border-radius: 0;
  color: white;
`;

const ShowSearchContainer = styled.div`
  position: relative;
`;

const ShowSearch = ({style, onSelect}) => {
    const [searchValue, setSearchValue] = useState();
    const [searchResults, setSearchResults] = useState();
    const [loading, setLoading] = useState(false);

    const handleSearch = async (value) => {
        setLoading(true);
        const response = await omdbApi.searchByTitle(value);

        const options = response?.data?.Search?.map(rawShow => ({
            title: rawShow.Title,
            poster_url: rawShow.Poster !== "N/A" && rawShow.Poster,
            imdb_id: rawShow.imdbID,
            year_label: rawShow.Year,
        }));

        setLoading(false);
        setSearchResults(options);
    };

    const debouncedHandleSearch = useCallback(lodash.debounce(handleSearch, 500), []);

    const clearSearch = () => {
        setSearchValue(null);
        setSearchResults(null);
    }

    const handleSelect = (show) => {
        clearSearch();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        console.log(value);
        setSearchValue(value);

        debouncedHandleSearch.cancel();
        if (!value) {
            clearSearch();
        }
        debouncedHandleSearch(value);
    }

    return (
        <ShowSearchContainer style={style}>
            {searchResults && <Overlay onClick={clearSearch}/>}
            <StyledInput.Search
                onChange={handleInputChange}
                value={searchValue}
                placeholder={'Search by title'}
                loading={loading}
            />
            {searchResults && (
                <SearchResultContainer>
                    {searchResults?.map(show => (
                        <div
                            key={show.imdb_id}
                            onClick={handleSelect}
                            style={{
                                padding: '4px 8px',
                                cursor: 'pointer',
                            }}
                        >
                            <Link to={routes.show.replace(':imdbId', show.imdb_id)}>
                                <ShowSnippet
                                    title={show.title}
                                    poster={show.poster_url}
                                    year={show.year_label}
                                />
                            </Link>
                        </div>
                    ))}
                </SearchResultContainer>
            )}
        </ShowSearchContainer>
    )
}

export default ShowSearch;
