import React, {useEffect, useState} from 'react';
import api from "../network/api";
import ShowGrid from "../components/ShowGrid";
import mixpanel from 'mixpanel-browser';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";


const HomePage = () => {
    const [showsPopularWithFriends, setShowsPopularWithFriends] = useState();

    const fetchPopularWithFriends = async () => {
        const response = await api.fetchShowsPopularWithFriends();
        setShowsPopularWithFriends(response.data);
    }

    useEffect(() => {
        mixpanel.track("Viewed Home Page");
        fetchPopularWithFriends();
    }, []);

    return (
        <div style={{padding: 16, background: 'black', textAlign: 'left'}}>
            <ScrollToTopOnMount/>
            <h2>Popular with friends</h2>
            {showsPopularWithFriends ? (
                <>
                    {showsPopularWithFriends.length > 0 ? (
                        <ShowGrid shows={showsPopularWithFriends}/>
                    ) : (
                        <div>No recommendations yet. Search and add a show to your recommendation list.</div>
                    )}
                </>
            ) : (
                <div>
                    Loading...
                </div>
            )}
        </div>
    );
}

export default HomePage;