import React from 'react';


const ShowSnippet = ({poster, title, year}) => (
    <div style={{display: 'flex', alignItems: 'center', height: 80, textAlign: 'left'}}>
        <div style={{width: 40, paddingRight: 8}}>
            {poster && (
                <img style={{width: '100%', height: 'auto'}} src={poster} alt={title}/>
            )}
        </div>
        <div>
            <div>{title}</div>
            <div>{year}</div>
        </div>
    </div>
);

export default ShowSnippet;