import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';
import api from "../network/api";
import ShowGrid from "../components/ShowGrid";
import mixpanel from "mixpanel-browser";
import AuthContext from "../authContext";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {Tabs} from 'antd';


const FriendDetailPage = () => {
    const {uuid} = useParams();
    const [user, setUser] = useState();
    const [recommendations, setRecommendations] = useState();
    const [watchlistedShows, setWatchlistedShows] = useState();

    useEffect(() => {
        mixpanel.track("Viewed Friend Detail Page");
        fetchUser();
        fetchRecommendations();
        fetchWatchlistedShows();
    }, []);

    const fetchUser = async () => {
        const response = await api.fetchUserByUuid(uuid);
        setUser(response.data);
    }

    const fetchRecommendations = async () => {
        const response = await api.fetchSavedShows({
            user__uuid: uuid,
            recommended: true,
        });
        setRecommendations(response.data.results);
    }

    const fetchWatchlistedShows = async () => {
        const response = await api.fetchSavedShows({
            user__uuid: uuid,
            watched: false,
        })
        setWatchlistedShows(response.data.results);
    };

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            {user && (
                <div>
                    <h1>{user.username}</h1>
                    <Tabs defaultActiveKey={"1"}>
                        <Tabs.TabPane tab={"Recommendations"} key={"1"}>
                            {recommendations ? (
                                <>
                                    {recommendations.length > 0 ? (
                                        <ShowGrid savedShows={recommendations} referrerUuid={uuid}/>
                                    ) : (
                                        <div>No recommendations yet</div>
                                    )}
                                </>
                            ) : (
                                <div>Loading...</div>
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={"Watchlist"} key={"2"}>
                            {watchlistedShows ? (
                                <>
                                    {watchlistedShows.length > 0 ? (
                                        <ShowGrid savedShows={watchlistedShows} referrerUuid={uuid}/>
                                    ) : (
                                        <div>No watchlisted shows yet</div>
                                    )}
                                </>
                            ) : (
                                <div>Loading...</div>
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            )}
        </div>
    )
}

export default FriendDetailPage;