import React from 'react';
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import ShowThumbnail from "./ShowThumbnail";


const ShowGridContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 32px;
  
  @media(min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;


export default ({savedShows, shows, onClick, onUpdate, referrerUuid, ...props}) => {
    return (
        <ShowGridContainer {...props}>
            {savedShows?.map(savedShow => (
                <ShowThumbnail
                    key={savedShow.uuid}
                    savedShow={savedShow}
                    referrerUuid={referrerUuid}
                />
            ))}
            {shows?.map(show => (
                <ShowThumbnail
                    key={show.uuid}
                    show={show}
                    referrerUuid={referrerUuid}
                />
            ))}
        </ShowGridContainer>
    )
}