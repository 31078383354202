import React, {useState, useEffect, useContext} from 'react';
import api from "../network/api";
import AuthContext from "../authContext";
import {Link} from "react-router-dom";
import routes from "../routes";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";


const FriendsPage = () => {
    const [friends, setFriends] = useState();
    const [followers, setFollowers] = useState();
    const {user} = useContext(AuthContext);

    const fetchFriends = async () => {
        const response = await api.fetchFollowers({
            following_user: user.uuid
        });
        setFriends(response.data.results.map(result => result.user));
    }

    const fetchFollowers = async () => {
        const response = await api.fetchFollowers();
        setFollowers(response.data.results.map(result => result.following_user));
    }

    useEffect(() => {
        mixpanel.track("Viewed Friends Page");
        fetchFriends();
        fetchFollowers();
    }, []);

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            <h1>Friends Page</h1>
            <h2>Following</h2>
            {friends ? (
                <ul>
                    {friends?.map(friend => (
                        <li key={friend.uuid}>
                            <Link to={routes.friend.replace(':uuid', friend.uuid)}>
                                {friend.username}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <div>Loading...</div>
            )}
            <h2>Followers</h2>
            {followers ? (
                <ul>
                    {followers?.map(follower => (
                        <li key={follower.uuid}>
                            <Link to={routes.friend.replace(':uuid', follower.uuid)}>
                                {follower.username}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
};

export default FriendsPage;
