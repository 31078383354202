import axios from 'axios';


let baseUrl = 'https://api.purepotatoes.app/v1';
if (process.env.REACT_APP_API_HOST) {
    baseUrl = process.env.REACT_APP_API_HOST;
}

const publicAxios = axios.create({
    baseURL: baseUrl,
});

const baseAxios = axios.create({
    baseURL: baseUrl,
});

baseAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Token ${token}`;
    return config;
});

baseAxios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
    }
    return Promise.reject(error);
})

const login = (username, password) => {
    return publicAxios.post('/dj-rest-auth/login/', {
        username,
        password,
    });
};

const facebookLogin = (accessToken) => {
    return publicAxios.post('/dj-rest-auth/facebook/', {
        access_token: accessToken,
    })
};

const saveShow = (data) => {
    return baseAxios.post(`/saved-shows/`, data);
};

const fetchSavedShows = (params) => {
    return baseAxios.get('/saved-shows/', {
        params,
    });
}

const updateSavedShow = (uuid, data) => {
    return baseAxios.patch(`/saved-shows/${uuid}/`, data);
}

const removeSavedShow = (uuid) => {
    return baseAxios.delete(`/saved-shows/${uuid}/`);
}

const fetchFollowers = params => {
    return baseAxios.get('/followers/', {
        params,
    });
};

const fetchUser = () => {
    return baseAxios.get('/user/');
};

const fetchUserByUuid = uuid => {
    return baseAxios.get(`/users/${uuid}/`);
};

const fetchShowsPopularWithFriends = () => {
    return baseAxios.get('/shows/popular-with-friends/');
}

const api = {
    login,
    facebookLogin,
    saveShow,
    fetchSavedShows,
    updateSavedShow,
    removeSavedShow,
    fetchFollowers,
    fetchUser,
    fetchUserByUuid,
    fetchShowsPopularWithFriends,
}

export const exceptions = {
    SAVED_SHOW_EXISTS: 'saved_show_exists',
}

export default api;