import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Route, Switch, useHistory, useLocation} from "react-router-dom";
import routes from "./routes";
import ShowSearch from "./components/ShowSearch";
import {Drawer, Modal} from "antd";
import ShowDetailPage from "./pages/ShowDetailPage";
import HomePage from "./pages/HomePage";
import WatchlistPage from "./pages/WatchlistPage";
import styled from "styled-components";
import FriendsPage from "./pages/FriendsPage";
import AuthContext from "./authContext";
import api from "./network/api";
import FriendDetailPage from "./pages/FriendDetailPage";
import {RiBookmarkFill, RiBookmarkLine, RiHomeFill, RiHomeLine, RiUserFill, RiUserLine} from "react-icons/all";
import {IconContext} from "react-icons";
import ProfilePage from "./pages/ProfilePage";
import mixpanel from 'mixpanel-browser';


const NavMenuContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

const BottomNavMenu = ({style}) => {
    const location = useLocation();
    const [lastActiveRoute, setLastActiveRoute] = useState();

    const navRoutes = [
        {
            to: routes.home,
            activeIcon: <RiHomeFill/>,
            inactiveIcon: <RiHomeLine/>,
        },
        {
            to: routes.watchlist,
            activeIcon: <RiBookmarkFill/>,
            inactiveIcon: <RiBookmarkLine/>,
        },
        {
            to: routes.profile,
            activeIcon: <RiUserFill/>,
            inactiveIcon: <RiUserLine/>,
        },
    ]

    useEffect(() => {
        setLastActiveRoute(location.pathname);
    }, [])

    return (
        <div
            style={{
                ...style,
                background: 'black',
                borderTop: '1px solid dimGrey',
                padding: '16px 8px',
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            }}>
                <IconContext.Provider value={{
                    color: 'white',
                    size: '24px',
                }}>
                    {navRoutes.map(navRoute => {
                        const isActive = location.pathname === navRoute.to || navRoute.to === lastActiveRoute;

                        return (
                            <NavLink to={navRoute.to} key={navRoute.to} onClick={() => setLastActiveRoute(navRoute.to)}>
                                {isActive ? navRoute.activeIcon : navRoute.inactiveIcon}
                            </NavLink>
                        )
                    })}
                </IconContext.Provider>
            </div>
        </div>
    )
}


const AuthenticatedApp = () => {
    const [selectedShow, setSelectedShow] = useState();
    const {user, setUser, setIsAuthenticated} = useContext(AuthContext);
    const history = useHistory();

    const handleShowSelected = async (show) => {
        setSelectedShow(show);
    }

    const fetchUser = async () => {
        const userResponse = await api.fetchUser();
        const user = userResponse.data;
        console.log(user);
        setUser(user);
        mixpanel.identify(user.uuid);
        mixpanel.people.set({
            "username": user.username,
            "$name": user.username,
            "first_name": user.first_name,
            "last_name": user.last_name,
            "friends_count": user.friends_count,
            "followers_count": user.followers_count,
        });
    }

    useEffect(() => {
        fetchUser();
    }, []);

    if (!user) {
        return (
            <div>Loading</div>
        )
    }

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    background: 'black',
                    padding: 16,
                    borderBottom: '1px solid dimGrey',
                    zIndex: 1000,
                }}
            >
                {/*<NavMenuContainer>*/}
                {/*    <Link to={routes.home}>Home</Link>*/}
                {/*    <Link to={routes.watchlist}>Watchlist</Link>*/}
                {/*    <Link to={routes.friends}>Friends</Link>*/}
                {/*    <div onClick={handleLogout}>Logout</div>*/}
                {/*</NavMenuContainer>*/}
                <div>
                    <ShowSearch
                        style={{}}
                        onSelect={handleShowSelected}
                    />
                </div>
            </div>
            <Drawer
                visible={!!selectedShow}
                closable
                onClose={() => setSelectedShow(null)}
                height={'90vh'}
                placement={'bottom'}
            >
                {selectedShow && (
                    <ShowDetailPage
                        show={selectedShow}
                        onUpdate={() => {
                        }}
                    />
                )}
            </Drawer>
            <div style={{marginTop: 80, paddingBottom: 80}}>
                <Switch>
                    <Route path={routes.home} exact>
                        <HomePage onShowClicked={show => setSelectedShow(show)}/>
                    </Route>
                    <Route path={routes.watchlist} exact>
                        <WatchlistPage onShowClicked={show => setSelectedShow(show)}/>
                    </Route>
                    <Route path={routes.show} exact>
                        <ShowDetailPage/>
                    </Route>
                    <Route path={routes.friends} exact>
                        <FriendsPage/>
                    </Route>
                    <Route path={routes.friend} exact>
                        <FriendDetailPage/>
                    </Route>
                    <Route path={routes.profile} exact>
                        <ProfilePage/>
                    </Route>
                </Switch>
            </div>
            <BottomNavMenu style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
            }}/>
        </div>
    )
}

export default AuthenticatedApp;