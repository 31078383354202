import React, {useContext, useEffect, useState} from 'react';
import {Form, Input, Button, Alert} from 'antd';
import api from "../network/api";
import FacebookLogin from 'react-facebook-login';
import {useHistory} from 'react-router-dom';
import routes from "../routes";
import AuthContext from "../authContext";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";


const LoginPage = () => {
    const history = useHistory();
    const {setIsAuthenticated} = useContext(AuthContext);
    const [error, setError] = useState(false);

    const handleLogin = async (values) => {
        try {
            const response = await api.login(
                values.email,
                values.password
            );
            if (response.status === 200) {
                const token = response.data.key;
                handleLoginSuccess(token);
            }
        } catch (e) {
            setError(true);
        }
    };

    const handleFacebookLogin = async response => {
        console.log(response);
        const apiResponse = await api.facebookLogin(response.accessToken);
        console.log(apiResponse);
        handleLoginSuccess(apiResponse.data.key);
    }

    const handleLoginSuccess = async token => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        history.push(routes.home);
    }

    useEffect(() => {
        mixpanel.track("Viewed Login Page");
    }, [])

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            <Form
                onFinish={handleLogin}
            >
                <Form.Item
                    label={"Email"}
                    name={"email"}
                    rules={[{required: true, message: 'Please input your email'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={'Password'}
                    name={'password'}
                    rules={[{required: true, message: 'Please input your password'}]}
                >
                    <Input.Password/>
                </Form.Item>
                {error && <Alert style={{marginBottom: 16}} message="Could not login with the username and password"
                                 type="error"/>}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                {/*<FacebookLogin*/}
                {/*    appId="402022177585457"*/}
                {/*    fields="name,email,picture"*/}
                {/*    callback={handleFacebookLogin}*/}
                {/*/>*/}
            </Form>
        </div>
    )
}

export default LoginPage;