import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Modal, Tabs} from "antd";
import AuthContext from "../authContext";
import {RiUserLine} from "react-icons/all";
import api from "../network/api";
import ShowGrid from "../components/ShowGrid";
import {HeartFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import routes from "../routes";
import mixpanel from "mixpanel-browser";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";


const ProfilePage = () => {
    const {user, setIsAuthenticated} = useContext(AuthContext);
    const [watchlistedShows, setWatchlistedShows] = useState();

    useEffect(() => {
        mixpanel.track("Viewed Profile Page");
        fetchRecommendations();
        fetchWatchlistedShows();
    }, []);

    const handleLogout = () => {
        Modal.confirm({
            title: "Logout?",
            onOk: () => {
                localStorage.removeItem("token");
                setIsAuthenticated(false);
            }
        })
    }

    const [recommendations, setRecommendations] = useState();
    const fetchRecommendations = async () => {
        const response = await api.fetchSavedShows({
            user__uuid: user.uuid,
            ordering: '-created_time',
            recommended: true,
        });
        setRecommendations(response.data.results);
    }

    const fetchWatchlistedShows = async () => {
        const response = await api.fetchSavedShows({
            user__uuid: user.uuid,
            watched: false,
        })
        setWatchlistedShows(response.data.results);
    };

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    <Avatar size={'large'} icon={<RiUserLine style={{marginTop: 8}}/>}/>
                    <span style={{marginLeft: 8}}>
                        {user.username}
                    </span>
                </div>
                <div onClick={handleLogout}>Logout</div>
            </div>
            <div style={{
                marginTop: 32,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                textAlign: 'center',
                fontSize: 18
            }}>
                <div style={{marginRight: 32}}>
                    <Link to={routes.friends} style={{color: 'white'}}>
                        <div>{user.friends_count}</div>
                        <div>Following</div>
                    </Link>
                </div>
                <div style={{marginLeft: 32}}>
                    <Link to={routes.friends} style={{color: 'white'}}>
                        <div>{user.followers_count}</div>
                        <div>Followers</div>
                    </Link>
                </div>
            </div>
            <div style={{marginTop: 32}}>
                <Tabs defaultActiveKey={"1"}>
                    <Tabs.TabPane tab={"Recommendations"} key={"1"}>
                        {recommendations ? (
                            <>
                                {recommendations.length > 0 ? (
                                    <ShowGrid savedShows={recommendations}/>
                                ) : (
                                    <div>No recommendations yet</div>
                                )}
                            </>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"Watchlist"} key={"2"}>
                        {watchlistedShows ? (
                            <>
                                {watchlistedShows.length > 0 ? (
                                    <ShowGrid savedShows={watchlistedShows}/>
                                ) : (
                                    <div>No watchlisted shows yet</div>
                                )}
                            </>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ProfilePage;