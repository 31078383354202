import 'antd/dist/antd.dark.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import React, {useContext, useState} from 'react';
import AuthenticatedApp from "./AuthenticatedApp";
import LoginPage from "./pages/LoginPage";
import routes from "./routes";
import AuthContext from "./authContext";


const Content = () => {
    const {isAuthenticated} = useContext(AuthContext);

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    {isAuthenticated ? (
                        <AuthenticatedApp/>
                    ) : (
                        <>
                            <Route path={routes.login} exact>
                            </Route>
                            <Route path={routes.home} exact>
                                <LoginPage/>
                            </Route>
                            <Redirect to={routes.home}/>
                        </>
                    )}
                </Switch>
            </BrowserRouter>
        </div>
    );
}

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState();

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            user,
            setUser,
        }}>
            <Content/>
        </AuthContext.Provider>
    )
};

export default App;

