import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    setIsAuthenticated: () => {
    },
    user: null,
    setUser: () => {
    },
});

export default AuthContext;
