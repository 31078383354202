import React, {useContext, useEffect, useState} from 'react';
import api from "../network/api";
import ShowGrid from "../components/ShowGrid";
import mixpanel from "mixpanel-browser";
import AuthContext from "../authContext";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";


const WatchlistPage = ({onShowClicked}) => {
    const [watchlistedShows, setWatchlistedShows] = useState();
    const {user} = useContext(AuthContext);

    const fetchWatchlistedShows = async () => {
        const response = await api.fetchSavedShows({
            user__uuid: user.uuid,
            watched: false,
        })
        setWatchlistedShows(response.data.results);
    };

    useEffect(() => {
        mixpanel.track("Viewed Watchlist Page");
        fetchWatchlistedShows();
    }, []);

    return (
        <div style={{padding: 16}}>
            <ScrollToTopOnMount/>
            <h1>Watchlist</h1>
            {watchlistedShows ? (
                <>
                    {watchlistedShows.length > 0 ? (
                        <ShowGrid savedShows={watchlistedShows} onClick={onShowClicked}/>
                    ) : (
                        <div>No watchlisted shows yet. Search and add a show</div>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
};

export default WatchlistPage;